var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid mt-auto footer"},[_c('div',{staticClass:"row justify-content-center",attrs:{"id":"social-footer"}},[_c('div',{staticClass:"p-0 col-12 justify-content-center my-5"},[_c('p',{staticClass:"text-center  "},[_vm._v(" "+_vm._s(_vm.$t("¡SÍGUENOS EN NUESTRAS REDES!"))+" ")]),_c('ul',{staticClass:"nav justify-content-center"},_vm._l((_vm.socials),function(social,index){return _c('li',{key:index+'icon',staticClass:"nav-item nav-link"},[_c('a',{attrs:{"href":social.link}},[_c('i',{staticClass:"social-icon",class:social.icon})])])}),0)])]),_c('div',{staticClass:"container-fluid"},[_vm._m(0),_c('div',{staticClass:"row justify-content-center mb-2"},[_c('ul',{staticClass:"nav justify-content-center ",attrs:{"id":"nav-footer"}},_vm._l((_vm.menuHeader),function(route,index){return _c('li',{key:index,staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":route.linkType == 'page' ? {
                name: 'page',
                params:{
                  page: route.routerName
                }
              } : route.routerName}},[_vm._v(" "+_vm._s(route.linkName.toUpperCase())+" ")])],1)}),0)]),_c('div',{staticClass:"row justify-content-center"},[_c('ul',{staticClass:"nav justify-content-center "},[_vm._l((_vm.menuBottom),function(route,index){return _c('li',{key:index,staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":route.linkType == 'page' ? {
            name: 'page',
            params:{
              page: route.routerName
            }
          } : route.routerName}},[_vm._v(_vm._s(route.linkName.toUpperCase()))])],1)}),_c('li',{staticClass:"nav-item"},[(_vm.$i18n.locale == 'en')?_c('a',{staticClass:"nav-link",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.changeLocale('es')}}},[_c('i',{staticClass:"bi bi-globe"}),_vm._v(" "+_vm._s(_vm.$t("Español").toUpperCase()))]):_c('a',{staticClass:"nav-link",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.changeLocale('en')}}},[_c('i',{staticClass:"bi bi-globe"}),_vm._v(" "+_vm._s(_vm.$t("English").toUpperCase()))])])],2)]),_vm._m(1),_c('div',{staticClass:"row justify-content-center"},[_c('p',{staticClass:"my-3 small",attrs:{"id":"copyright"}},[_vm._v("© "+_vm._s(new Date().getFullYear())+" "+_vm._s(_vm.$t("Viendo Movies - Todos los derechos reservados")))])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row justify-content-center",attrs:{"id":"footer"}},[_c('div',{staticClass:"col-4 mx-auto text-center my-3"},[_c('img',{attrs:{"src":require("@/assets/img/viendomovies-logo-white.png"),"alt":"Viendo Movies Logo","width":"58px","height":"80px"}})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"text-center my-4"},[_c('img',{staticClass:"mx-auto d-block",attrs:{"src":require("@/assets/img/somos-tv-logo-white.png"),"alt":"Somos Tv Logo","width":"117px","height":"35px"}})])])}]

export { render, staticRenderFns }